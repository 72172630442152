import React from 'react'
import {BsGithub, BsLinkedin} from 'react-icons/bs';

const SocialMedia = () => {
let blank = "_blank"

  return (
    <div className='app__social'>
        <div>
          <a href='https://www.linkedin.com/in/dean-hornibrook/' target={blank}>
            <BsLinkedin/>
          </a>
        </div>
        <div>
          <a href='https://github.com/Dean-Hornibrook' target={blank}>
            <BsGithub/>
          </a>
        </div>
    </div>
  )
}

export default SocialMedia